.stepper {

}

.step-button {
    width: 48px;
    height: 48px;
    border: 2px solid var(--black);
    border-radius: 50%;
    text-decoration: none;
    color: var(--black);
}

.step-button.active {
    background-color: var(--black);
    color: var(--white);
}

.blue-bg .step-button.active {
    color: var(--blue);
}

.step-button:visited {
    color: var(--black);
}

.step-button.active:visited {
    color: var(--white);
}

.blue-bg .step-button.active:visited {
    color: var(--blue);
}

@media only screen and (max-width: 767px) {
    .step-button {
        width: 40px;
        height: 40px;
    }
}