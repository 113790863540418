.tile {
    border: 1px solid var(--ks-divider-transparent-light);
    border-radius: 6px;
    box-shadow: var(--ks-box-shadow-shallow);
    background: white;
}

.tile.row-style {
    border: none;
    border-bottom: 1px solid var(--ks-divider);
    box-shadow: none;
    background: none;
    border-radius: 0;
}

.tile.row-style:hover {
    background: var(--ks-divider-light);
}

.tile.row-style:hover .image-wrapper {
    border-color: var(--ks-divider-dark);
}

@media only screen and (max-width: 767px) {
}