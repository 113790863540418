#signup-modal-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(40px);
    z-index: 3;
    opacity: 1;
}

#signup-modal {
    transition: all 0.5s ease-in-out;
    opacity: 0;
}

#signup-modal.dim {
    filter: blur(12px);
    pointer-events: none;
}

#signup-modal.dim button {
    background-color: transparent;
}

#submit-error {
    position: absolute;
    top: -120%;
    right: 0;
    left: 0;
    opacity: 0;
}

#submit-error.fade-slide-in-d, #signup-modal.fade-slide-in-d, #signup-modal-wrapper.fade-slide-in-d {
    opacity: 0;
}

#submit-error.fade-slide-out-d, #signup-modal.fade-slide-out-d, #signup-modal-wrapper.fade-slide-out-d {
    opacity: 1;
}

#signup-modal.error-shake {
    opacity: 1;
}


#signup-close {
    position: absolute;
    top: 32px;
    right: 32px;
    z-index: 4;
}

@keyframes errorShake {
    0%, 100% { transform: translateX(0); }
    20%, 60% { transform: translateX(-3px); }
    40%, 80% { transform: translateX(3px); }
}

.error-shake {
    animation: errorShake 0.4s ease-in-out;
}

iframe[title="reCAPTCHA"] {
    border: 1px solid transparent;
    border-radius: 4px;
    flex: 1;
    display: flex;
    padding: 2px;
    box-sizing: border-box;
}

.error iframe[title="reCAPTCHA"] {
    border: 1px solid red;
    border-radius: 4px;
}

@media only screen and (max-width: 767px) {
    #signup-close {
        position: fixed;
        top: 32px;
        right: 32px;
    }

    @keyframes errorShake {
        0%, 100% { transform: translateX(0); }
        20%, 60% { transform: translateX(-2px); }
        40%, 80% { transform: translateX(2px); }
    }
}