@font-face {
  font-family: 'SF Mono';
  src: url('./assets/fonts/SF-Mono-Regular.otf') format('opentype');
  font-weight: normal; /* Specify the weight of the font; Change if necessary */
  font-style: normal;  /* Specify the style of the font; Change if necessary */
}

@font-face {
  font-family: 'SF Mono';
  src: url('./assets/fonts/SF-Mono-Medium.otf') format('opentype');
  font-weight: 500; /* Specify the weight of the font; Change if necessary */
  font-style: normal;  /* Specify the style of the font; Change if necessary */
}

@font-face {
  font-family: 'SF Mono';
  src: url('./assets/fonts/SF-Mono-Bold.otf') format('opentype');
  font-weight: bold; /* Specify the weight of the font; Change if necessary */
  font-style: normal;  /* Specify the style of the font; Change if necessary */
}

@font-face {
  font-family: 'SF Pro';
  src: url('./assets/fonts/SF-Pro-Text-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('./assets/fonts/SF-Pro-Display-Regular.otf') format('opentype');
  font-weight: normal; /* Specify the weight of the font; Change if necessary */
  font-style: normal;  /* Specify the style of the font; Change if necessary */
}


@font-face {
  font-family: 'SF Pro Display';
  src: url('./assets/fonts/SF-Pro-Display-Medium.otf') format('opentype');
  font-weight: 500; /* Specify the weight of the font; Change if necessary */
  font-style: normal;  /* Specify the style of the font; Change if necessary */
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('./assets/fonts/SF-Pro-Display-Semibold.otf') format('opentype');
  font-weight: 600; /* Specify the weight of the font; Change if necessary */
  font-style: normal;  /* Specify the style of the font; Change if necessary */
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('./assets/fonts/SF-Pro-Display-Bold.otf') format('opentype');
  font-weight: bold; /* Specify the weight of the font; Change if necessary */
  font-style: normal;  /* Specify the style of the font; Change if necessary */
}

@font-face {
  font-family: 'SF Pro';
  src: url('./assets/fonts/SF-Pro-Text-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro';
  src: url('./assets/fonts/SF-Pro-Text-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

/* POPPINS FONT */
@font-face {
  font-family: "Poppins";
  src: url('./assets/fonts/Poppins-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url('./assets/fonts/Poppins-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url('./assets/fonts/Poppins-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url('./assets/fonts/Poppins-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

*, *::before, *::after {
  box-sizing: border-box;
}

:root {
  --ks-brand-color: #9BB0FF;
  --black: #000000;
  --yellow: rgb(255, 221, 87);
  --white: #ffffff;
  --blue: rgb(155, 176, 255);
  --red: rgb(255, 87, 87);
  --text-blue: #3758D4;
  --grey: #f4f6fd;
  --blue-gradient: linear-gradient(2deg, #9BB0FF 0%, #FFF 100%);
  --text-secondary: #3F4A74;
  --ks-divider: #E6E8F0;
  --ks-divider-transparent: rgba(0,0,0,0.24);
  --ks-divider-transparent-light: rgba(0,0,0,0.12);
  --ks-divider-light: #f2f4f8;
  --ks-divider-dark: #b9bfd6;
  --ks-box-shadow: 0 12px 32px rgba(155, 176, 255, 0.3);
  --ks-box-shadow-yellow: 0 12px 32px rgba(255, 221, 87, 0.3);
  --ks-box-shadow-up: 0 -12px 32px rgba(230, 232, 240, 0.5);
  --ks-box-shadow-gray: 0 12px 24px rgba(0, 0, 0, 0.04);
  --ks-box-shadow-shallow: 0 2px 8px rgba(155, 176, 255, 0.3);                    /* Keeping the original */
}

input[type="checkbox"] {
  box-sizing: border-box;
  min-width: 20px;
  min-height: 20px;
  max-width: 20px;
  max-height: 20px;
  padding: 0;
  border: 2px solid var(--black);
  appearance: none;
  background-color: transparent;
  outline: none;
  transition: outline 0.1s;
  margin: 0;
  border-radius: 4px;
}

input[type="checkbox"]:checked {
  background-size: cover;
}

input[type="checkbox"]:not(:disabled):checked {
  border-color: var(--black);
  background-color: var(--black);
  background-image: url('./media/check-white32@3x.png');
}

.blue-gradient {
  background: var(--blue-gradient);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.object-fit {
  object-fit: contain;
}

.content-width {
  width: 1080px;
  max-width: 1080px;
} 

.black-bg {
  background-color: var(--black);
}

.grey-bg {
  background-color: var(--grey);
}

.red-bg {
  background-color: var(--red);
}

.header {
  background-color: var(--yellow);
  border-radius: 16px;
}

a {
  cursor: pointer;
}

.shiny-bg {
  /* transparent background linear gradient to the grey color */
  background: linear-gradient(2deg, rgba(155, 176, 255, 100) 0%, rgba(155, 176, 255, .8) 100%);
  backdrop-filter: blur(40px);
}

.blue-bg {
  background-color: var(--blue);
}

.yellow-bg {
  background-color: var(--yellow);
}

.flex-1 {
  flex: 1;
}

.white-bg {
  background-color: var(--white);
}

.fixed {
  position: fixed;
}

.flex-wrap {
  flex-wrap: wrap;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.overflow-y-scroll {
  overflow-y: scroll;
  scroll-behavior: smooth;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.no-pointer {
  pointer-events: none;
}

.yes-pointer {
  pointer-events: auto;
}

*, *::before, *::after {
  box-sizing: border-box;
  margin: 0;
  -webkit-font-smoothing: antialiased;
}

html, body, #root {
  font-family: "SF Pro Display", sans-serif;
  font-style: normal;
  background-color: white;
  width: 100vw;
  height: 100vh;
  width: -webkit-fill-available;
  height: -webkit-fill-available;
  overflow: hidden;
}

p {
  padding: 0;
  margin: 0;
}

.centered {
  align-items: center;
  justify-content: center;
}

.space-between {
  justify-content: space-between;
}

.bold {
  font-weight: bold;
}

.medium {
  font-weight: 500;
}

.overflow-hidden {
  overflow: hidden;
}

.preline {
  white-space: pre-line;
}

.hide {
  opacity: 0;
}

.display-none {
  display: none;
}

.size-xxs {
  font-size: 10px;
  line-height: 10px;
}

.line-xxs {
  line-height: 13px;
}

.size-xs {
  font-size: 12px;
  line-height: 12px;
}

.line-xs {
  line-height: 15px;
}

.size-s {
  font-size: 14px;
  line-height: 14px;
}

.line-s {
  line-height: 18px;
}

.size-m {
  font-size: 16px;
  line-height: 16px;
}

.line-m {
  line-height: 20px;
}

.size-l {
  font-size: 22px;
  line-height: 22px;
}

.line-l {
  line-height: 24px;
}

.size-xl {
  font-size: 28px;
  line-height: 28px;
}

.round-s {
  border-radius: 4px;
}

.round-m {
  border-radius: 8px;
}

.line-xl{
  line-height: 32px;
}

.size-xxl {
  font-size: 36px;
  line-height: 36px;
}

.size-xxxl {
  font-size: 40px;
  line-height: 40px;
}

.size-display {
  font-size: 44px;
  line-height: 44px;
}

.pad-xs {
  padding: 8px;
}

.pad-s {
  padding: 12px;
}

.pad-m {
  padding: 16px;
}

.pad-l {
  padding: 24px;
}

.pad-xl {
  padding: 32px;
}

.poppins {
  font-family: 'Poppins';
}

.v-pad-xs {
  padding-top: 8px;
  padding-bottom: 8px;
}

.v-pad-s {
  padding-top: 12px;
  padding-bottom: 12px;
}

.v-pad-m {
  padding-top: 16px;
  padding-bottom: 16px;
}

.v-pad-l {
  padding-top: 24px;
  padding-bottom: 24px;
}

.v-pad-xl {
  padding-top: 32px;
  padding-bottom: 32px;
}

.pad-h-xs {
  padding-left: 8px;
  padding-right: 8px;
}

.pad-h-s {
  padding-left: 12px;
  padding-right: 12px;
}

.pad-h-m {
  padding-left: 16px;
  padding-right: 16px;
}

.pad-h-l {
  padding-left: 24px;
  padding-right: 24px;
}

.pad-h-xl {
  padding-left: 32px;
  padding-right: 32px;
}

.no-pad-right {
  padding-right: 0;
}

.v-gap-xxs {
  row-gap: 2px;
}

.v-gap-xs {
  row-gap: 4px;
}

.v-gap-s {
  row-gap: 8px;
}

.v-gap-m {
  row-gap: 12px;
}

.v-gap-l {
  row-gap: 16px;
}

.v-gap-xl {
  row-gap: 24px;
}

.v-gap-xxl {
  row-gap: 32px;
}

.v-gap-display {
  row-gap: 48px;
}

.h-gap-xxs {
  column-gap: 2px;
}

.h-gap-xs {
  column-gap: 4px;
}

.h-gap-s {
  column-gap: 8px;
}

.h-gap-m {
  column-gap: 12px;
}

.h-gap-l {
  column-gap: 16px;
}

.h-gap-xl {
  column-gap: 24px;
}

.h-gap-xxl {
  column-gap: 32px;
}

.h-gap-display {
  column-gap: 48px;
}

.flex {
  display: flex;
}

.grow-0 {
  flex-grow: 0;
}

.grow-1 {
  flex-grow: 1;
}

.shrink-1 {
  flex-shrink: 1;
}

.shrink-0 {
  flex-shrink: 0;
}

.basis-auto {
  flex-basis: auto;
}

.basis-50-desktop {
  flex-basis: 50%;
}

.basis-75-desktop {
  flex-basis: 75%;
}

.height-100 {
  height: 100%;
}

.round-12 {
  border-radius: 12px;
}

.round-8 {
  border-radius: 8px;
}

p.white {
  color: white;
}

.white {
  color: white;
}

.z-2 {
  z-index: 2;
}

p.black {
  color: black;
}

a.black {
  color: black;
}

.max-content {
  width: max-content;
}

.flex-2 {
  display: flex;
  flex: 2;
}

.flex-3 {
  display: flex;
  flex: 3;
}

.uppercase {
  text-transform: uppercase;
  letter-spacing: 0.4px;
}

p.secondary {
  color: var(--text-secondary);
}

.space-around {
  justify-content: space-around;
}

button {
  background: initial;
  color: initial;
  border: initial;
  box-shadow: initial;
  cursor: pointer;
  min-width: initial;
}

.overflow-auto {
  overflow: auto;
}

.sf-pro {
  font-family: 'SF Pro';
}

.sf-pro-display {
  font-family: 'SF Pro Display';
}

.sf-mono {
  font-family: 'SF Mono';
}

.number {
  font-family: 'SF Mono';
}

.text-mono {
  font-family: 'SF Mono';
  text-wrap: wrap;
}

.hero-text {
  font-weight: 600;
}

.pad-b-4 {
  padding-bottom: 12px;
}

.align-start {
  align-items: flex-start;
}

.align-end {
  align-items: flex-end;
}

.justify-end {
  justify-content: flex-end;
}

.relative {
  position: relative;
}

.ks-button {
  font-weight: 500;
  border-radius: 4px;
  padding: 4px 8px;
}

.ks-button.large {
  padding: 8px 24px;
}

.ks-button.white {
  background-color: white;
}



.ks-button.brand {
  background-color: var(--ks-brand-color);
}

.ks-description {
  line-height: 20px;
}

.keystone-divider {
  background-color: var(--ks-divider);
  height: 1px;
}

.width-100 {
  width: 100%;
}

.no-pad-top {
  padding-top: 0;
}

.no-pad-bot {
  padding-bottom: 0;
}

.pad-right-24 {
  padding-right: 24px;
}

.pad-left-24 {
  padding-left: 24px;
}


.pad-right-16 {
  padding-right: 16px;
}

.keystone-notification-circle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: var(--ks-brand-color);
  position: absolute;
  right: 16px;
}

.desktop-hide {
  display: none !important;
}

.vertical.reverse {
  flex-direction: column-reverse;
}

.flex-end {
  justify-content: flex-end;
}

.vertical {
  flex-direction: column;
}

.horizontal {
  flex-direction: row;
}

.align-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.center-text {
  text-align: center;
  justify-content: center;
}

.card {
  border-radius: 6px;
  box-shadow: var(--ks-box-shadow);
  background: white;
  position: relative;
  box-sizing: border-box;
}

.card-light {
  border-radius: 6px;
  background: var(--white);
  border: 1px solid var(--ks-divider);
  position: relative;
  box-sizing: border-box;
}



.card-light.collapsed {
}

.card-secondary {
  border-radius: 6px;
  background: var(--grey);
  position: relative;
  box-sizing: border-box;
  transition: all 0.5s;
}

.space-around {
  justify-content: space-around;
}

.right-align {
  text-align: right;
}

.clickable {
  cursor: pointer;
  transition: all 0.08s ease-in-out;
}
.overflow-x-hidden {
  overflow-x: hidden;
}

.blue, p.blue {
  color: var(--text-blue);
}

.blue-light, p.blue-light {
  color: var(--blue);
}

.yellow, p.yellow {
  color: var(--yellow);
}

.modal {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  transition: all 0.3s ease-in-out;
  background-color: rgba(0,0,0,0.2);
}

input, textarea, select {
  -webkit-text-size-adjust: 100%;
  text-size-adjust: 100%;
}

.no-grow {
  flex-grow: 0;
}

.invert {
  filter: invert(1);
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 3;
  opacity: 1;
}

.modal-content {
  max-width: 640px;
  max-height: 98%;
}

@media (hover: hover) {
  .ks-button:hover {
    background: var(--ks-divider);
  }
  
  .ks-button.white:hover {
    background: var(--ks-divider-light);
  }

  .card-light .clickable:hover {
    background-color: var(--grey);
  }
  
  .clickable:hover {
    border-color: var(--ks-divider-dark);
  }
}

@media only screen and (max-width: 1020px) {
  .content-width {
    width: 960px;
    max-width: 960px;
  } 
}

@media only screen and (max-width: 960px) {
  .content-width {
    width: 767px;
    max-width: 767px;
  } 
}

@media only screen and (max-width: 767px) {
  .modal-content {
    max-width: 96%;
  }

  .reverse {
      flex-direction: column-reverse;
  }

  .mobile-hide {
      display: none !important;
  }
  
  .desktop-hide {
      display: flex !important;
  }

  .size-xxs {
    font-size: 8px;
    line-height: 8px;
  }
  
  .line-xxs {
    line-height: 11px;
  }

  .size-xs {
      font-size: 10px;
      line-height: 10px;
  }

  .line-xs {
    line-height: 13px;
  }
  
  .size-s {
      font-size: 12px;
      line-height: 12px;
  }

  .line-s {
    line-height: 16px;
  }
  
  .size-m {
      font-size: 14px;
      line-height: 14px;
  }

  .line-m {
    line-height: 18px;
  }
  
  .size-l {
      font-size: 18px;
      line-height: 18px;
  }

  .line-l {
    line-height: 22px;
  }
  
  .size-xl {
      font-size: 24px;
      line-height: 24px;
  }

  .size-xxl {
    font-size: 32px;
    line-height: 32px;
  }

  .size-xxxl {
    font-size: 36px;
    line-height: 36px;
  }

  .size-display {
      font-size: 40px;
      line-height: 40px;
  }

  .pad-xs {
      padding: 4px;
  }
  
  .pad-s {
      padding: 10px;
  }
  
  .pad-m {
      padding: 12px;
  }
  
  .pad-l {
      padding: 16px;
  }
  
  .pad-xl {
      padding: 20px;
  }
  
  .v-pad-xs {
    padding-top: 4px;
    padding-bottom: 4px;
  }

  .v-pad-s {
      padding-top: 6px;
      padding-bottom: 6px;
  }

  .v-pad-m {
      padding-top: 12px;
      padding-bottom: 12px;
  }

  .v-pad-l {
      padding-top: 16px;
      padding-bottom: 16px;
  }

  .v-pad-xl {
      padding-top: 20px;
      padding-bottom: 20px;
  }

  .pad-h-xs {
      padding-left: 4px;
      padding-right: 4px;
  }
  
  .pad-h-s {
      padding-left: 6px;
      padding-right: 6px;
  }
  
  .pad-h-m {
      padding-left: 12px;
      padding-right: 12px;
  }
  
  .pad-h-l {
      padding-left: 16px;
      padding-right: 16px;
  }
  
  .pad-h-xl {
      padding-left: 20px;
      padding-right: 20px;
  }

  .v-gap-xxs {
      row-gap: 0px;
  }
  
  .v-gap-xs {
      row-gap: 2px;
  }
  
  .v-gap-s {
      row-gap: 10px;
  }
  
  .v-gap-m {
      row-gap: 12px;
  }
  
  .v-gap-l {
      row-gap: 16px;
  }
  
  .v-gap-xl {
      row-gap: 32px;
  }
  
  .v-gap-xxl {
      row-gap: 20px;
  }
  
  .v-gap-display {
      row-gap: 28px;
  }

  .h-gap-xxs {
      column-gap: 0px;
  }
  
  .h-gap-xs {
      column-gap: 2px;
  }
  
  .h-gap-s {
      column-gap: 4px;
  }
  
  .h-gap-m {
      column-gap: 8px;
  }
  
  .h-gap-l {
      column-gap: 16px;
  }
  
  .h-gap-xl {
      column-gap: 32px;
  }
  
  .h-gap-xxl {
      column-gap: 24px;
  }
  
  .h-gap-display {
      column-gap: 24px;
  }

  .width-50 {
      flex-basis: 40%;
      flex-grow: 1;
  }

  .no-pad-top {
      padding-top: 0;
  }
  
  .no-pad-bot {
      padding-bottom: 0;
  }

  .flex-2.mobile-flex-reset, .flex-1.mobile-flex-reset {
    flex: initial;
  }

  .mobile-width-100 {
    width: 100%;
  }


  .mobile-vertical {
    flex-direction: column;
  }

  .mobile-vertical.reverse {
    flex-direction: column-reverse;
  } 

  .mobile-horizontal {
    flex-direction: row;
  }

  .mobile-horizontal.reverse {
    flex-direction: row-reverse;
  }
  .mobile-flex-1 {
    flex: 1;
  }

  input, textarea, select {
    font-size: 16px !important;
  }

  .content-width {
    width: 100%;
  }

  .round-s {
    border-radius: 2px;
  }
  
  .round-m {
    border-radius: 6px;
  }

  .basis-50-desktop, .basis-75-desktop {
    flex-basis: 100%;
  }

  .mobile-align-center {
    align-items: center;
  }

  .mobile-no-pad-top {
    padding-top: 0;
  }
}