.keystone-select {
    width: initial;
    background-color: rgba(255, 255, 255, 0.2);
    border: 2px solid rgba(255, 255, 255, 0.2);
    color: white;
    border-radius: 4px;
    padding: 8px;
    font-weight: 500;
    outline: none;
    -webkit-appearance: none;
}

/* ensure height on ios */


.keystone-select:focus {
    border: 2px solid var(--black);
}

@media only screen and (max-width: 767px) {
    .keystone-select {
        padding: 10px 8px;
    }
}