#landing, #signup-modal-wrapper, .full-screen {
    height: 100vh;
    width: 100vw;
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    overflow-x: hidden;
}

#cookie-banner {
    position: fixed;
    bottom: 0;
    z-index: 2;
    left: 0;
    right: 0;
}

.value-prop-image, .testimonial-image {
    width: 64px;
    height: 64px;
    object-fit: contain;
}

.shift-value-prop-up {
    margin-top: -32px;
}

.visual-image {
    max-width: calc(100% + 64px);
    margin-left: -32px;
    margin-top: -32px;
    object-fit: contain;
}

.visual-image.shift-down {
    margin-bottom: -38px;
}

.visual-image.shift-up {
    margin-top: -24px;
}

.shift-section-up {
    margin-top: -32px;
}

.primary-button {
    background-color: var(--yellow);
    border-radius: 4px;
    border: 2px solid var(--black);
    box-shadow: 2px 2px 0px rgba(0, 0, 0, 100);
    color: var(--black);
    padding: 10px 16px;
    transition: all 0.04s ease-in-out;
}

.primary-button.on-black {
    background-color: var(--white);
    border-radius: 6px;
    box-shadow: 2px 2px 0px rgba(0, 0, 0, 100);
    color: var(--black);
    padding: 10px 16px;
    transition: all 0.04s ease-in-out;
}

.primary-button:hover {
    background-color: black;
    box-shadow: none;
    border-color: black;
    color: white;
}

.wordmark-image {
    width: 174px;
    object-fit: contain;
    cursor: pointer;
    transition: all 0.04s ease-in-out;
    z-index: 2;
}

.wordmark-image:hover {
    scale: 1.02;
}

.wordmark-image:active {
    scale: 0.98;
}

.shift-button-down {
    margin-bottom: -20px;
    z-index: 2;
}

.stroke-test {
    color: var(--black);
    -webkit-text-stroke: 20px var(--text-blue);
}

.fix-stroke {
    paint-order: stroke fill;
}

#overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(40px);
    z-index: 3;
    pointer-events: none;
    opacity: 1;
}

#menu {
    padding: 4px;
    row-gap: 8px;
    z-index: 2;
}

.menu-bar {
    width: 24px;
    height: 2px;
    background-color: var(--black);
    transition: all 0.14s ease-in-out;
    position: relative;
}

.menu-bar.open:first-of-type {
    rotate: 45deg;
    top: 5px;
}

.menu-bar.open:last-of-type {
    rotate: -45deg;
    top: -5px;
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeSlideIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeSlideOut {
    from {
        opacity: 1;
        transform: translateY(0);
    }
    to {
        opacity: 0;
        transform: translateY(20px);
    }
}

.fade-out {
    opacity: 1; /* Start with the element hidden */
    animation: fadeOut 0.6s ease-out forwards;
    animation-delay: 0.1s; /* Delay for the second text */
}

.fade-in {
    opacity: 0; /* Start with the element hidden */
    animation: fadeIn 0.6s ease-out forwards; /* Adjust the duration and timing function as needed */
}

.fade-slide-in {
    opacity: 0; /* Start with the element hidden */
    animation: fadeSlideIn 1s ease-out forwards; /* Adjust the duration and timing function as needed */
}

.fade-slide-out {
    opacity: 0; /* Start with the element hidden */
    animation: fadeSlideOut 1s ease-out forwards; /* Adjust the duration and timing function as needed */
}

.fade-slide-in-d {
    opacity: 0; /* Start with the element hidden */
    animation: fadeSlideIn 0.6s ease-out forwards;
    animation-delay: 0.1s; /* Delay for the second text */
}

.fade-slide-out-d {
    opacity: 1; /* Start with the element hidden */
    animation: fadeSlideOut 0.6s ease-out forwards;
    animation-delay: 0.1s; /* Delay for the second text */
}

.fade-slide-in-d2 {
    opacity: 0; /* Start with the element hidden */
    animation: fadeSlideIn 0.6s ease-out forwards;
    animation-delay: 0.2s; /* Delay for the second text */
}

.fade-slide-in-d3 {
    opacity: 0; /* Start with the element hidden */
    animation: fadeSlideIn 0.6s ease-out forwards;
    animation-delay: 0.3s; /* Delay for the second text */
}

.fade-slide-in-d4 {
    opacity: 0; /* Start with the element hidden */
    animation: fadeSlideIn 0.6s ease-out forwards;
    animation-delay: 0.4s; /* Delay for the second text */
}

.fade-slide-in-d5 {
    opacity: 0; /* Start with the element hidden */
    animation: fadeSlideIn 0.6s ease-out forwards;
    animation-delay: 0.5s; /* Delay for the second text */
}

.fade-slide-in-d6 {
    opacity: 0; /* Start with the element hidden */
    animation: fadeSlideIn 0.6s ease-out forwards;
    animation-delay: 0.6s; /* Delay for the second text */
}

.scroll-top {
    background-color: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(20px);
    border: 2px solid var(--black);
    position: absolute;
    bottom: 24px;
    right: 24px;
    z-index: 2;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    padding: 8px;
    cursor: pointer;
}

.pad-header-top {
    padding-top: 64px;
}

@media only screen and (max-width: 767px) {
    .shift-value-prop-up {
        margin-top: -26px;
    }

    .value-prop-image {
        width: 52px;
        height: 52px;
    }

    .shift-section-up {
        margin-top: -22px;
    }

    .visual-image {
        max-width: calc(100% + 48px);
        width: calc(100% + 48px);
        margin-left: -24px;
        object-fit: contain;
    }

    #signup-close {
        position: initial;
    }

    .wordmark-image {
        width: 124px;
    }

    #menu-links {
        position: fixed;
        background-color: var(--white);
        top: 0;
        left: 0;
        right: 0;
        padding-top: 72px;
        padding-bottom: 36px;
    }
}