.input {
    border-radius: 4px;
    background-color: rgba(255, 255, 255, 0.2);
    border: 2px solid transparent;
    outline: none;
    color: white;
    font-weight: 500;
}

.input:focus {
    /* border: 2px solid var(--black); */
    background-color: var(--blue);
    color: var(--black);
}

.input.error {
    background-color: var(--red);
}

.input.large {
    padding: 8px;
}

.input.small {
    padding: 8px 12px;
}

.input.on-brand {}

.input.on-white, .keystone-select.on-white {
    background-color: var(--ks-divider-light);
    box-shadow: none;
    border: none;
}

.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* style placeholder color */
.input::placeholder {
    color: rgba(255, 255, 255, 0.6);
}

.input:focus::placeholder {
    color: rgba(0, 0, 0, 0.8);
}


.input.on-brand {
    color: #ffffff;
}

.input.on-brand::placeholder {
    color: #ffffff90;
}

.input.align-right {
    text-align: right;
}
@media only screen and (max-width: 767px) {
    .input.large {
        padding: 6px;
    }
}